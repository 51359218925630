import React, { useState } from 'react'
import Icon from './icons';
import Link from './link'

const SidebarItem = ({ path, order, title, subtitle, slug, articles = [] }) => {
  const isCurrentPage = path.split('/')[1] === slug;
  const slugs = articles && articles.map(article => article.slug);
  const haveArticles = slugs.indexOf(path.split('/')[1]) > -1;
  const [collapsed, setCollapsed] = useState(isCurrentPage ? !isCurrentPage : !haveArticles);

  return (
    <div className={`${order === 2 && "ml-4"} ${order === 3 && "ml-8"} ${order === 4 && "ml-12"}`}>
      <div>
        <Link to={`/${slug}`} className={`${isCurrentPage && "bg-gray-100 font-medium"} flex items-center justify-between  hover:bg-gray-100`}>
          <span className="py-3 pl-5 w-full">{order === 1 ? title : subtitle}</span>
        
          {
            !!(articles && articles.length) && (
              <button
                className={`${!collapsed && "rotate-180"} transform transition-transform duration-150 focus:outline-none cursor-pointer py-4 px-5`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setCollapsed(!collapsed)
                }}
              >
                <Icon symbol="arrow-down" />
              </button>
            )
          }
        </Link>
      </div>
      
      {
        !!(articles && articles.length) && (
          <div className={`${ collapsed ? "hidden" : "block"}`}>
            { articles.map(item => <SidebarItem key={item.id} path={path} {...item} />) }
          </div>
        )
      }
    </div>
  )
}

export default SidebarItem
