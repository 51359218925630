import React, { useState, useEffect } from 'react'
import Link from './link'
import Icon from './icons'
import useWindowDimensions from '../lib/useWindowWidth';

const navProps = [
  {
    title: 'Documentation',
    items: [
      {
        title: 'Release notes',
        url: '/release-notes',
      },
      {
        title: 'Admin documentation',
        url: '/',
      },
      {
        title: 'Developer documentation',
        url: 'https://developer.okta.com/docs/',
      },
    ]
  },
  {
    title: 'Knownledge base',
    url: '/',
  },
  {
    title: 'Comunity',
    items: [
      {
        title: 'Questions',
        url: '/',
      },
      {
        title: 'Discussions',
        url: '/',
      },
      {
        title: 'Webinars',
        url: '/',
      },
    ]
  },
  {
    title: 'Product',
    items: [
      {
        title: 'Ideas',
        url: 'https://support.okta.com/help/s/ideas?_ga=2.202304657.1426675116.1624605149-2147346572.1622720610',
      },
      {
        title: 'Roadmap',
        url: '/',
      },
      {
        title: 'Product hub',
        url: '/',
      },
    ]
  },
  {
    title: 'Training',
    url: 'https://www.okta.com/services/education-services/?_ga=2.37058656.1332672071.1623061054-2147346572.1622720610',
  },
]

const Dropdown = ({ title, items, setCollapsed, collapsed }) => (
  <div className={`${collapsed ? "hidden" : "block"} lg:group-hover:block fixed inset-0 lg:absolute lg:top-full lg:left-0 lg:bottom-auto lg:right-auto lg:py-4 mt-16 lg:mt-0 z-20 bg-white lg:border border-gray-200`}>
    <div
      role="button"
      tabIndex={0}
      onClick={() => setCollapsed(true)}
      className="flex lg:hidden px-4 py-6 border-t border-b lg:border-0 border-gray-200"
    >
      <span className="inline-flex items-center transform rotate-90 mr-4">
        <Icon symbol="arrow-down"/>
      </span>
      <span>{title}</span>
    </div>

    <div className="ml-4 lg:ml-0 border-l-14 lg:border-l-0 border-gray-100 flex flex-col">
      {
        items.map((item, i) => (
          <Link
            key={`dropdown_item_${i}`}
            to={item.url}
            className="inline-flex items-center p-6 lg:py-2 text-gray-800 hover:bg-gray-000 hover:text-gray-900 border-b lg:border-0 border-gray-200 whitespace-nowrap"
          >
            {item.title}
          </Link>
        ))
      }
    </div>
  </div>
)

const NavbarItem = ({ title, url, items }) => {
  const width = useWindowDimensions();
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if(width > 1024) {
      setCollapsed(true)
    }
  }, [width]);

  return (
    url
      ? <Link to={url} className="w-full inline-flex items-center whitespace-nowrap text-gray-800 hover:text-gray-900 py-6 px-4 lg:px-0 lg:mr-8 border-t lg:border-0 last:border-b border-gray-200 last:mr-0">{title}</Link> 
      : <div
          role="button"
          tabIndex={0}
          onClick={() => width <= 1024 && setCollapsed(!collapsed)}
          className="group relative cursor-pointer py-6 px-4 lg:px-0 lg:mr-8 last:mr-0 border-t lg:border-0 last:border-b border-gray-200"
        >
          <div className="text-gray-800 hover:text-gray-900 flex items-center">
            <span className="mr-3">{title}</span>
            <div className="ml-auto transform -rotate-90 lg:rotate-0">
              <Icon symbol="arrow-down" />
            </div>
          </div>

          <Dropdown title={title} setCollapsed={setCollapsed} collapsed={collapsed} items={items} />
        </div>
  )
}

const Navbar = ({ showNav }) => (
  <div className={`${showNav ? "block" : "hidden"} fixed z-20 inset-0 mt-16 lg:mt-0 bg-white lg:relative lg:flex items-center`}>
    { navProps.map((item, i) => <NavbarItem key={`nav_item_${i}`} {...item} />) }
  </div>
)

export default Navbar
