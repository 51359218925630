import React from 'react'

const Search = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.5" cy="10.5" r="7.50001" stroke="#41414B" strokeWidth="2"/>
    <path d="M21.0001 20.9999L15.9001 15.8999" stroke="#41414B" strokeWidth="2"/>
  </svg>
)

export default Search
