import React from 'react'
import Logo from './logo'
import Link from './link'
import ArrowDown from './arrow-down'
import Search from './search'
import MenuIcon from './menu'
import CrossIcon from './cross'

function Icon (props) {
  switch (props.symbol) {
    case 'logo':
      return <Logo />
    case 'link':
      return <Link />
    case 'arrow-down':
      return <ArrowDown />
    case 'search':
      return <Search />
    case 'menu':
      return <MenuIcon />
    case 'cross':
      return <CrossIcon />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
