import React from 'react'
import Link from './link'
import Icon from './icons'

const Breadcrumbs = ({ mainArticle }) => (
  <div className="flex pt-4 px-6">
    <Link to="/" className="text-gray-600 text-sm border-b border-gray-600 mr-2 last:mr-0">Help Center</Link>
    { mainArticle && <span className="inline-flex items-center justify-center transform -rotate-90 mr-2"><Icon symbol="arrow-down" /></span> }
    {
      mainArticle &&
        <Link to={`/${mainArticle.slug}`} className="text-gray-600 text-sm border-b border-gray-600">{mainArticle.title}</Link>
    }
  </div>
)

export default Breadcrumbs
