import React from 'react'
import Link from './link'

const Tile = ({ order, title, subtitle, description, slug }) => (
  <div className="w-full sm:w-1/2 2xl:w-2/6 p-3" style={{ minWidth: "310px" }}>
    <Link to={`/${slug}`} className="flex flex-col justify-between p-8 h-full min-h-56.5 group hover:bg-blue bg-gray-000 transition-color duration-300 rounded-bl-4xl rounded-tr-4xl">
      <div className="">
        <h4 className="text-t4 text-blue group-hover:text-white font-medium mb-4 transition-color duration-300">
          { order > 1 ? subtitle : title }
        </h4>
        <p className="text-gray-600 group-hover:text-white mb-4 transition-color duration-300">{description}</p>
      </div>
      
      <span className="text-blue group-hover:text-white font-medium transition-color duration-300">Learn more</span>
    </Link>
  </div>
)

const Tiles = ({ title, products }) => (
  <div className="mb-16 last:mb-0">
    { title &&  <h3 className="text-t3 text-gray-800 font-medium mb-6">{title}</h3> }

    <div className="flex flex-wrap -m-3">
      { products.map((tile, i) => <Tile key={`tile_${i}`} {...tile} />) }
    </div>
  </div>
)

export default Tiles
