import React from 'react'

const CrossIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.34326 6.65674L17.657 17.9704" stroke="#41414B" strokeWidth="2"/>
    <path d="M17.6567 6.65674L6.34303 17.9704" stroke="#41414B" strokeWidth="2"/>
  </svg>
)

export default CrossIcon
