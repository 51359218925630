import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import isAbsoluteUrl from 'is-absolute-url';
import Icon from './icons';

const Link = ({ to, ...props }) =>
  isAbsoluteUrl(to) ? (
    <a href={to} {...props} target="_blank" rel="noreferrer">
      <span className="mr-3">{props.children}</span>
      <Icon symbol="link" />
    </a>
  ) : (
    <GatsbyLink to={to} {...props} />
  );

export default Link;