import React, { useState, useEffect } from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { useStaticQuery, graphql } from 'gatsby';
import useWindowDimensions from '../lib/useWindowWidth';
import Header from './header';

import '../static/fonts/fonts.css';
import '../styles/layout.css';

export default function Layout(props) {
  const {seo, site} = useStaticQuery(graphql`
    query LayoutQuery {
      site: datoCmsSite {
        favicon: faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
        }
      }
      seo: datoCmsSeo(slug: {eq: "default-123"}) {
        meta: seoMetaTags {
            tags
        }
      }
    }
  `);

  const width = useWindowDimensions();
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    if(width > 1024) {
      setShowNav(false)
    }
  }, [width]);

  return <React.Fragment>
    <HelmetDatoCms seo={seo?.meta} favicon={site?.favicon}>
      <html lang="en" />
      <meta HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8" />
    </HelmetDatoCms>

    <div className={`${showNav && "overflow-hidden max-h-screen"}`}>
      <Header showNav={showNav} setShowNav={setShowNav} />
      {props.children}
    </div>
  </React.Fragment>
}
