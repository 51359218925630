import React from 'react'

const ArrowDown = () => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L7 7L13 1" stroke="#41414B"/>
  </svg>
)

export default ArrowDown
