import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Link from '../link'
import * as style from "./index.module.css"

const articlesQuery = graphql`{
  allDatoCmsArticle {
    nodes {
      slug
      title
    }
  }
}`;

const SearchBar = ({ collapsed }) => {
  const {allDatoCmsArticle: {nodes: articles}} = useStaticQuery(articlesQuery);
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);
  const result = value !== '' ? articles.filter(article => article.title.indexOf(value) > -1) : [];

  return (
    <div className={`${collapsed ? "hidden" : "flex"} justify-center items-center bg-blue p-4`}>
      <div className="">
        <select className={`${style.select} cursor-pointer w-24 sm:w-48 rounded-none py-1.5 pl-4 pr-6 border border-400 h-10 outline-none`}>
          <option>All Content</option>
          <option>Documentation</option>
          <option>Knowledge base</option>
          <option>Discussions</option>
          <option>Events</option>
          <option>Roadmap</option>
        </select>
      </div>

      <div className="relative w-full max-w-lg">
        <input
          onFocus={() => setFocus(true)}
          onBlur={() => setTimeout(() => setFocus(false), 300)}
          onChange={(e) => setValue(e.target.value) }
          type="text"
          placeholder="Search our content"
          className="w-full py-2 px-4 border border-400 outline-none h-10"
        />

        <div className={`${ focus && result.length > 0 ? "flex" : "hidden" } flex-col max-h-40 overflow-auto absolute z-10 top-full left-0 right-0 bg-white border border-gray-200 py-4`}>
          {
            result.map(item => (
              <Link to={`/${item.slug}`} className="inline-flex items-center text-gray-800 hover:bg-gray-000 px-6 py-2">{item.title}</Link>
            ))
          }
        </div>
      </div>
      

      <div className="">
        <button className="text-gray-300 font-medium bg-white px-4 py-2 border border-400 outline-none focus:outline-none h-10">Search</button>
      </div>
    </div>
  )
}

export default SearchBar
