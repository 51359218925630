import React from 'react'

const Link = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.8 8.20001V13H1V2.20001H5.8" stroke="#41414B" strokeWidth="1.5"/>
    <path d="M5.79999 8.2L13 1" stroke="#41414B" strokeWidth="1.5"/>
    <path d="M9.40002 1H13V4.6" stroke="#41414B" strokeWidth="1.5"/>
  </svg>
)

export default Link
