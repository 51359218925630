import React from 'react'

const Logo = () => (
  <svg
    width="190"
    height="26"
    viewBox="0 0 190 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.81606 17.2052C5.65552 17.2052 3.90803 15.4398 3.90803 13.257C3.90803 11.0743 5.65552 9.30887 7.81606 9.30887C9.97659 9.30887 11.7241 11.0743 11.7241 13.257C11.7559 15.4077 10.0084 17.2052 7.81606 17.2052ZM7.81606 5.32861C3.49499 5.32861 0 8.85949 0 13.2249C0 17.5904 3.49499 21.1213 7.81606 21.1213C12.1371 21.1213 15.6321 17.5904 15.6321 13.2249C15.6639 8.85949 12.1689 5.32861 7.81606 5.32861Z" fill="#00287A"/>
    <path d="M21.6055 15.8889C21.6055 15.247 22.368 14.9581 22.7811 15.4075C24.751 17.4297 27.9918 20.8964 27.9918 20.8964C28.0236 20.9285 28.0871 21.0248 28.2777 21.089C28.3413 21.1211 28.4684 21.1211 28.6272 21.1211H32.154C32.7894 21.1211 32.9801 20.3828 32.6941 19.9976L26.8162 13.9309L26.4985 13.6099C25.8312 12.8075 25.8948 12.4865 26.6573 11.684L31.2961 6.45188C31.5821 6.06669 31.3914 5.36051 30.756 5.36051H27.5787C27.4516 5.36051 27.3563 5.36051 27.261 5.39261C27.0704 5.45681 27.0068 5.52101 26.9433 5.58521C26.9433 5.58521 24.3697 8.40991 22.7811 10.1111C22.3363 10.5926 21.5737 10.2716 21.5737 9.62967V0.641978C21.5737 0.192593 21.1924 0 20.9065 0H18.3011C17.8563 0 17.6339 0.28889 17.6339 0.57778V20.4791C17.6339 20.9285 18.0152 21.0569 18.3329 21.0569H20.9383C21.3513 21.0569 21.6055 20.768 21.6055 20.447V20.2544V15.8889Z" fill="#00287A"/>
    <path d="M42.9569 20.3828L42.6709 17.7507C42.6392 17.3976 42.2897 17.1408 41.9402 17.205C41.7495 17.2371 41.5271 17.2371 41.3365 17.2371C39.2395 17.2371 37.5555 15.6001 37.4284 13.4815V13.2568V10.0469C37.4284 9.62966 37.7462 9.27658 38.1592 9.27658H41.6542C41.9084 9.27658 42.2897 9.05188 42.2897 8.6025V6.09879C42.2897 5.6173 41.9719 5.36051 41.686 5.36051H38.1592C37.7462 5.36051 37.4284 5.07162 37.3967 4.65434V0.641978C37.3967 0.385187 37.206 0 36.7295 0H34.1241C33.8064 0 33.4886 0.192593 33.4886 0.609879V13.4815C33.584 17.7507 37.0789 21.1853 41.3047 21.1853C41.6542 21.1853 42.0037 21.1532 42.3532 21.1211C42.7662 21.0569 42.9887 20.7359 42.9569 20.3828Z" fill="#00287A"/>
    <path d="M51.4397 17.2051C49.2792 17.2051 47.5317 15.4396 47.5317 13.2569C47.5317 11.0742 49.2792 9.30875 51.4397 9.30875C53.6003 9.30875 55.3478 11.0742 55.3478 13.2569C55.3478 15.4075 53.6003 17.2051 51.4397 17.2051ZM61.8294 17.0767C59.6053 17.0767 59.2876 16.2742 59.2876 13.2569V13.2248V6.00256C59.2876 5.74577 59.0969 5.32849 58.5886 5.32849H55.9832C55.6655 5.32849 55.2842 5.58528 55.2842 6.00256V6.32355C54.1404 5.64947 52.8377 5.29639 51.408 5.29639C47.0869 5.29639 43.5919 8.82726 43.5919 13.1927C43.5919 17.5582 47.0869 21.089 51.408 21.089C53.3461 21.089 55.1254 20.3508 56.4916 19.1952C57.2224 20.3187 58.3979 21.089 60.2725 21.089C60.5903 21.089 62.2742 21.1532 62.2742 20.3508V17.5261C62.2742 17.3335 62.0836 17.0767 61.8294 17.0767Z" fill="#00287A"/>
    <path d="M82.3546 5.32861V21.2176H80.8295V13.7706H71.5201V21.1855H69.9633V5.32861H71.5201V12.3904H80.8295V5.32861H82.3546Z" fill="#646E75"/>
    <path d="M90.8058 21.4424C89.9797 21.4424 89.1854 21.2819 88.4864 20.9609C87.7874 20.6399 87.1837 20.1905 86.6754 19.6448C86.167 19.0991 85.754 18.4572 85.468 17.7189C85.1821 16.9806 85.0232 16.2102 85.0232 15.3757C85.0232 14.5411 85.1821 13.7707 85.468 13.0645C85.754 12.3263 86.167 11.7164 86.6754 11.1707C87.1837 10.625 87.7874 10.2077 88.4864 9.88676C89.1854 9.56577 89.9479 9.40527 90.774 9.40527C91.6001 9.40527 92.3944 9.56577 93.0934 9.88676C93.7924 10.2077 94.3961 10.6571 94.9045 11.2028C95.4128 11.7485 95.7941 12.3905 96.0801 13.0966C96.366 13.8028 96.4931 14.5732 96.4931 15.3757C96.4931 15.5041 96.4931 15.6004 96.4931 15.7288C96.4931 15.8571 96.4931 15.9213 96.4613 15.9855H86.58C86.6118 16.6275 86.7707 17.2053 87.0249 17.7189C87.279 18.2325 87.5968 18.7139 87.978 19.0991C88.3593 19.4843 88.8041 19.7732 89.2807 19.9979C89.7891 20.2226 90.2974 20.3189 90.8693 20.3189C91.2506 20.3189 91.6001 20.2547 91.9814 20.1584C92.3627 20.0621 92.6804 19.9016 92.9981 19.7411C93.3158 19.5485 93.6018 19.3559 93.8242 19.067C94.0784 18.8102 94.269 18.5214 94.3961 18.2004L95.6988 18.5535C95.5082 18.9707 95.2857 19.388 94.9362 19.7411C94.6185 20.0942 94.2372 20.3831 93.7924 20.672C93.3794 20.9288 92.9028 21.1214 92.3627 21.2819C91.9178 21.3461 91.3777 21.4424 90.8058 21.4424ZM95.0633 14.7658C95.0316 14.1238 94.8727 13.5781 94.6185 13.0645C94.3643 12.551 94.0784 12.1016 93.6971 11.7485C93.3158 11.3633 92.871 11.0744 92.3944 10.8818C91.8861 10.6892 91.3777 10.5608 90.8058 10.5608C90.2339 10.5608 89.7255 10.6571 89.2172 10.8818C88.7088 11.1065 88.264 11.3954 87.9145 11.7485C87.5332 12.1337 87.2473 12.5831 86.9931 13.0966C86.7707 13.6102 86.6436 14.188 86.58 14.7979H95.0633V14.7658Z" fill="#646E75"/>
    <path d="M98.9398 4.87891H100.433V21.1851H98.9398V4.87891Z" fill="#646E75"/>
    <path d="M109.774 21.4422C108.789 21.4422 107.931 21.1854 107.201 20.704C106.47 20.1904 105.866 19.5805 105.39 18.8101V26.0003H103.896V9.56564H105.231V11.8126C105.707 11.0743 106.311 10.4965 107.074 10.0471C107.836 9.59774 108.63 9.37305 109.52 9.37305C110.314 9.37305 111.045 9.53354 111.712 9.88663C112.38 10.2076 112.952 10.657 113.428 11.2348C113.905 11.8126 114.286 12.4545 114.572 13.1607C114.858 13.899 114.985 14.6373 114.985 15.4076C114.985 16.2422 114.858 17.0126 114.604 17.7188C114.35 18.457 114 19.099 113.523 19.6447C113.047 20.1904 112.507 20.6398 111.871 20.9607C111.236 21.2817 110.537 21.4422 109.774 21.4422ZM109.361 20.0941C109.997 20.0941 110.537 19.9657 111.045 19.6768C111.554 19.42 111.998 19.0669 112.348 18.6175C112.697 18.1681 112.983 17.6867 113.174 17.1089C113.365 16.5632 113.46 15.9854 113.46 15.3755C113.46 14.7336 113.365 14.1558 113.142 13.578C112.92 13.0002 112.634 12.5187 112.221 12.0694C111.84 11.6521 111.395 11.299 110.855 11.0422C110.346 10.7854 109.774 10.657 109.171 10.657C108.789 10.657 108.376 10.7212 107.963 10.8817C107.55 11.0422 107.137 11.2348 106.788 11.5237C106.438 11.7805 106.12 12.1015 105.866 12.4545C105.612 12.8076 105.453 13.1607 105.39 13.5459V17.1089C105.58 17.5262 105.803 17.9113 106.057 18.2644C106.343 18.6175 106.629 18.9385 106.978 19.1953C107.328 19.4521 107.709 19.6768 108.09 19.8052C108.503 20.0299 108.916 20.0941 109.361 20.0941Z" fill="#646E75"/>
    <path d="M122.452 13.1604C122.452 12.1974 122.61 11.2665 122.96 10.3036C123.278 9.37269 123.786 8.50602 124.421 7.76775C125.057 7.02947 125.851 6.41959 126.773 5.93811C127.694 5.45663 128.743 5.23193 129.95 5.23193C131.348 5.23193 132.555 5.55292 133.54 6.1949C134.525 6.83688 135.256 7.67145 135.732 8.69861L134.525 9.46899C134.271 8.9554 133.953 8.50602 133.604 8.15293C133.254 7.79984 132.841 7.51095 132.428 7.28626C132.015 7.06157 131.602 6.90108 131.157 6.80478C130.712 6.70848 130.268 6.64428 129.855 6.64428C128.901 6.64428 128.075 6.83688 127.345 7.22206C126.614 7.60725 126.01 8.12083 125.533 8.73071C125.025 9.37269 124.676 10.0468 124.421 10.8492C124.167 11.6196 124.04 12.4221 124.04 13.2246C124.04 14.0912 124.199 14.9579 124.485 15.7604C124.803 16.5628 125.216 17.269 125.724 17.911C126.232 18.5209 126.868 19.0345 127.599 19.3875C128.329 19.7406 129.092 19.9332 129.918 19.9332C130.363 19.9332 130.808 19.869 131.284 19.7727C131.761 19.6764 132.206 19.4838 132.619 19.227C133.064 18.9703 133.445 18.6814 133.826 18.2962C134.207 17.9431 134.493 17.4937 134.748 16.9801L136.018 17.6542C135.764 18.2641 135.415 18.7777 134.97 19.227C134.525 19.6764 134.017 20.0616 133.445 20.3826C132.873 20.7036 132.301 20.9283 131.666 21.0888C131.03 21.2493 130.426 21.3456 129.823 21.3456C128.743 21.3456 127.758 21.1209 126.868 20.6394C125.978 20.1579 125.184 19.548 124.549 18.7777C123.913 18.0073 123.405 17.1406 123.023 16.1777C122.642 15.1826 122.452 14.1875 122.452 13.1604Z" fill="#646E75"/>
    <path d="M143.326 21.4424C142.5 21.4424 141.706 21.2819 141.007 20.9609C140.308 20.6399 139.704 20.1905 139.196 19.6448C138.687 19.0991 138.274 18.4572 137.988 17.7189C137.702 16.9806 137.543 16.2102 137.543 15.3757C137.543 14.5411 137.702 13.7707 137.988 13.0645C138.274 12.3263 138.687 11.7164 139.196 11.1707C139.704 10.625 140.308 10.2077 141.007 9.88676C141.706 9.56577 142.468 9.40527 143.294 9.40527C144.12 9.40527 144.915 9.56577 145.614 9.88676C146.313 10.2077 146.916 10.6571 147.425 11.2028C147.933 11.7485 148.314 12.3905 148.6 13.0966C148.886 13.8028 149.013 14.5732 149.013 15.3757C149.013 15.5041 149.013 15.6004 149.013 15.7288C149.013 15.8571 149.013 15.9213 148.981 15.9855H139.1C139.132 16.6275 139.291 17.2053 139.545 17.7189C139.799 18.2325 140.117 18.7139 140.498 19.0991C140.879 19.4843 141.324 19.7732 141.801 19.9979C142.309 20.2226 142.818 20.3189 143.389 20.3189C143.771 20.3189 144.12 20.2547 144.502 20.1584C144.883 20.0621 145.201 19.9016 145.518 19.7411C145.836 19.5485 146.122 19.3559 146.344 19.067C146.599 18.8102 146.789 18.5214 146.916 18.2004L148.219 18.5535C148.028 18.9707 147.806 19.388 147.456 19.7411C147.139 20.0942 146.757 20.3831 146.313 20.672C145.9 20.9288 145.423 21.1214 144.883 21.2819C144.438 21.3461 143.898 21.4424 143.326 21.4424ZM147.583 14.7658C147.552 14.1238 147.393 13.5781 147.139 13.0645C146.884 12.551 146.599 12.1016 146.217 11.7485C145.836 11.3633 145.391 11.0744 144.915 10.8818C144.406 10.6892 143.898 10.5608 143.326 10.5608C142.754 10.5608 142.246 10.6571 141.737 10.8818C141.229 11.1065 140.784 11.3954 140.435 11.7485C140.053 12.1337 139.767 12.5831 139.513 13.0966C139.291 13.6102 139.164 14.188 139.1 14.7979H147.583V14.7658Z" fill="#646E75"/>
    <path d="M161.055 21.2175H159.562V14.7015C159.562 13.3212 159.371 12.294 158.958 11.6842C158.545 11.0422 157.942 10.7533 157.084 10.7533C156.639 10.7533 156.194 10.8496 155.749 11.0101C155.304 11.1706 154.891 11.3953 154.51 11.7163C154.129 12.0052 153.811 12.3582 153.493 12.7755C153.207 13.1928 152.985 13.6422 152.858 14.0916V21.2175H151.365V9.56564H152.731V12.1977C153.207 11.3632 153.875 10.657 154.764 10.1434C155.654 9.62984 156.607 9.37305 157.624 9.37305C158.259 9.37305 158.799 9.50144 159.244 9.72613C159.689 9.95083 160.039 10.3039 160.293 10.7212C160.547 11.1385 160.738 11.6842 160.865 12.294C160.992 12.936 161.055 13.6101 161.055 14.3805V21.2175Z" fill="#646E75"/>
    <path d="M169.666 20.6395C169.57 20.6716 169.443 20.7358 169.284 20.8321C169.125 20.8963 168.935 20.9926 168.712 21.0568C168.49 21.121 168.236 21.1851 167.95 21.2493C167.664 21.3135 167.378 21.3456 167.06 21.3456C166.743 21.3456 166.425 21.3135 166.139 21.2172C165.853 21.121 165.599 20.9926 165.376 20.8C165.154 20.6074 164.963 20.3827 164.836 20.0938C164.709 19.8049 164.646 19.4839 164.646 19.0987V10.753H163.057V9.53325H164.646V5.61719H166.139V9.56535H168.808V10.7851H166.139V18.7135C166.171 19.1308 166.329 19.4518 166.584 19.6765C166.87 19.8691 167.156 19.9654 167.537 19.9654C167.95 19.9654 168.331 19.9012 168.681 19.7407C168.998 19.6123 169.221 19.4839 169.316 19.4518L169.666 20.6395Z" fill="#646E75"/>
    <path d="M176.243 21.4424C175.417 21.4424 174.622 21.2819 173.923 20.9609C173.224 20.6399 172.621 20.1905 172.112 19.6448C171.604 19.0991 171.191 18.4572 170.905 17.7189C170.619 16.9806 170.46 16.2102 170.46 15.3757C170.46 14.5411 170.619 13.7707 170.905 13.0645C171.191 12.3263 171.604 11.7164 172.112 11.1707C172.621 10.625 173.224 10.2077 173.923 9.88676C174.622 9.56577 175.385 9.40527 176.211 9.40527C177.037 9.40527 177.831 9.56577 178.53 9.88676C179.229 10.2077 179.833 10.6571 180.341 11.2028C180.85 11.7485 181.231 12.3905 181.517 13.0966C181.803 13.8028 181.93 14.5732 181.93 15.3757C181.93 15.5041 181.93 15.6004 181.93 15.7288C181.93 15.8571 181.93 15.9213 181.898 15.9855H172.017C172.049 16.6275 172.208 17.2053 172.462 17.7189C172.716 18.2325 173.034 18.7139 173.415 19.0991C173.796 19.4843 174.241 19.7732 174.718 19.9979C175.226 20.2226 175.734 20.3189 176.306 20.3189C176.688 20.3189 177.037 20.2547 177.418 20.1584C177.8 20.0621 178.117 19.9016 178.435 19.7411C178.753 19.5485 179.039 19.3559 179.261 19.067C179.515 18.8102 179.706 18.5214 179.833 18.2004L181.136 18.5535C180.945 18.9707 180.723 19.388 180.373 19.7411C180.056 20.0942 179.674 20.3831 179.229 20.672C178.816 20.9288 178.34 21.1214 177.8 21.2819C177.355 21.3461 176.815 21.4424 176.243 21.4424ZM180.5 14.7658C180.469 14.1238 180.31 13.5781 180.056 13.0645C179.801 12.551 179.515 12.1016 179.134 11.7485C178.753 11.3633 178.308 11.0744 177.831 10.8818C177.323 10.6892 176.815 10.5608 176.243 10.5608C175.671 10.5608 175.163 10.6571 174.654 10.8818C174.146 11.1065 173.701 11.3954 173.352 11.7485C172.97 12.1337 172.684 12.5831 172.43 13.0966C172.208 13.6102 172.081 14.188 172.017 14.7979H180.5V14.7658Z" fill="#646E75"/>
    <path d="M190 10.9138C188.983 10.9459 188.094 11.2348 187.331 11.7484C186.569 12.2941 186.06 13.0323 185.742 13.9632V21.2176H184.249V9.56566H185.679V12.3583C186.092 11.4595 186.696 10.7533 187.395 10.2718C188.125 9.75826 188.888 9.50146 189.714 9.50146C189.841 9.50146 189.936 9.50146 190 9.53356V10.9138Z" fill="#646E75"/>
  </svg>
)

export default Logo
