import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Link from './link'
import SidebarItem from './sidebarItem';

const articlesQuery = graphql`{
  allDatoCmsArticle(filter: {order: {eq: 1}}) {
    nodes {
      id
      slug
      title
      order
      articles {
        id
        slug
        title
        subtitle
        order
      }
    }
  }
}`;

const Sidebar = ({ path }) => {
  const {allDatoCmsArticle: {nodes: articles}} = useStaticQuery(articlesQuery);

  return (
    <aside className="hidden lg:block pt-6 pr-3 pb-10 pl-11 bg-gray-000" style={{ minWidth: "376px" }}>
      <div className="">
        <Link to="/" className={`${path === "/" && "bg-gray-100 font-medium"} flex items-center justify-between  hover:bg-gray-100`}>
          <span className="py-3 pl-5 w-full">Admin documentation</span>
        </Link>

        { articles.map(item => <SidebarItem key={item.id} path={path} {...item} />) }
      </div>
    </aside>
  )
}

export default Sidebar
