import React, { useState } from 'react'
import Link from './link'
import Icon from './icons'
import Navbar from './navbar'
import SearchBar from './searchbar'

const Header = ({ showNav, setShowNav }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <header className="bg-white">
      <div className="flex items-center px-4 lg:px-0 border-b border-gray-200">
        <div className="lg:ml-16 lg:mr-24">
          <Link to="/" className="inline-flex items-center" style={{ height: "70px" }}>
            <Icon symbol="logo" />
          </Link>
        </div>

        <Navbar showNav={showNav} />

        <div className="ml-auto flex items-center">
          <button className={`${showNav ? "hidden" : "flex"} focus:outline-none cursor-pointer lg:mr-16 pl-4`} onClick={() => setCollapsed(!collapsed)}>
            <Icon symbol="search" />
          </button>

          <button className="lg:hidden focus:outline-none cursor-pointer ml-6" onClick={() => setShowNav(!showNav)}>
            { showNav ? <Icon symbol="cross" /> : <Icon symbol="menu" /> }
          </button>
        </div>
      </div>

      <SearchBar collapsed={collapsed} />
    </header>
  )
}

export default Header
