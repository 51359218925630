import React from 'react'

const MenuIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 6H20" stroke="#41414B" strokeWidth="2"/>
    <path d="M4 12H20" stroke="#41414B" strokeWidth="2"/>
    <path d="M4 18H20" stroke="#41414B" strokeWidth="2"/>
  </svg>
)

export default MenuIcon
